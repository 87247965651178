<template>
  <section class="sliders">
    <h2 class="sliders__title">{{ title }}</h2>
    <div class="sliders__wrap">
      <swiper
        :loop="true"
        :slidesPerView="2"
        :spaceBetween="20"
        :speed="2000"
        :autoplay="{
          delay: 4000,
          disableOnInteraction: false,
        }"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        :breakpoints="{
          '576': {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          '1024': {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }"
      >
        <swiper-slide v-for="(item, index) in data" :key="item.id">
          <img
            :src="item.url"
            rel="preload"
            width="170"
            height="69"
            :alt="'slide ' + item.alt"
            @mouseover="
              memImageUrl = item.url;
              item.url = active[index].url;
            "
            @mouseleave="item.url = memImageUrl"
          />
        </swiper-slide>
      </swiper>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/swiper.scss";

import "swiper/components/navigation/navigation.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    active: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      memImageUrl: null,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/_mixins.scss";

.sliders {
  position: relative;
  overflow: hidden;
  max-width: 1366px;
  margin: 0 auto;
  color: #fff;
  @include property("margin-bottom", 100, 30);

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 32, 24, true, 768, 320);
    line-height: calc(40 / 32);
    color: #f9b80e;
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-top: 65px;
  }

  &__wrap {
    position: relative;
    padding: 25px 55px;
  }

  .swiper-container {
    width: calc(100vw - 170px);
    max-width: 946px;
    margin: 0 auto;

    @media (max-width: 575.98px) {
      width: calc(100vw - 100px);
    }
  }

  .swiper-slide {
    align-self: center;

    img {
      max-height: 90px;
      object-fit: contain;
    }
  }

  .swiper-button {
    &-prev,
    &-next {
      position: absolute;
      top: 50%;
      width: 31px;
      height: 31px;
      margin: 0;
      z-index: 10;
      cursor: pointer;
      background: #f9b80e;
      mask-image: url("~@/assets/images/arrow-slider.svg");
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
      transform: translateY(-50%);

      &::after,
      &::before {
        display: none;
      }

      @media (max-width: 1023.98px) {
        display: none;
      }
    }

    &-prev {
      left: 15px;
    }

    &-next {
      right: 15px;
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
</style>
